@import '@teliads/components/foundations/typography/typography';
@import '@teliads/components/foundations/breakpoints/mixins';
@import 'utils/hooks/useHorizontalScrollGradient/useHorizontalScrollGradient.scss';
@import '@purpurds/purpur/styles';

html {
  scroll-behavior: smooth;
}

:root {
  --purpur-rescale: 1;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.scroll-stop {
    overflow-y: hidden;

    @media (hover: hover) and (pointer: fine) {
      padding-right: 15px; // Required to remove jump due to browser scrollbar
    }
  }

  div[data-mobile-detector] {
    display: block;

    @include telia-breakpoint('md') {
      display: none;
    }
  }
}
