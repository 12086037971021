@import 'node_modules/@teliads/components/foundations/grid/variables';
@import '../../scss/variables.scss';

$input-width: 24rem;
$input-width-shortened: 20rem;
$transition-options-width: width 300ms;

.header-search-input {
  display: flex;
  align-items: center;
  position: absolute;
  right: $page-padding-xl;

  &--search {
    margin-left: auto;
  }

  &__search-text {
    display: none;

    @media screen and (min-width: $breakpoint-xl) {
      display: block;
    }
  }

  &--enter {
    width: 0;
  }

  &--enter-active {
    width: $input-width;
    transition: $transition-options-width;
  }

  &--enter-done {
    width: $input-width;
  }

  &--exit {
    width: $input-width-shortened;
  }

  &--exit-active {
    width: 0;
    transition: $transition-options-width;
  }

}

.search-button {
  &--enter-active {
    opacity: 0;
    position: absolute;
  }

  &--enter-done {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }

  &--exit {
    opacity: 0;
    position: absolute;
  }
}
